
export const dataTeam = {
  "faculty": [
    {
      "name":"Florian Shkurti",
      "image":"assets/team/florian.jpg",
      "description":"robot vision, machine learning, planning and control",
	"website":"http://www.cs.toronto.edu/~florian/",
	"twitter": "https://twitter.com/florian_shkurti",
	"googleScholar": "https://scholar.google.com/citations?user=BDmtLHsAAAAJ&hl=en"
    }
  ],
    "Postdocs":[
    {
      "name":"Mike Gimelfarb",
      "image":"assets/team/mike_gimelfarb_1.jpg",
      "description":"reinforcement learning, planning, learning to search",
      "website":"https://mike-gimelfarb.github.io/",
      "email":"mike.gimelfarb@mail.utoronto.ca",
      "linkedIn":"https://ca.linkedin.com/in/michael-gimelfarb-0ba5723b",
      "googleScholar":"https://scholar.google.com/citations?user=1tBgyLYAAAAJ&hl=en",
      "github": "https://github.com/mike-gimelfarb"
    },
    {
      "name":"Qi Chen",
      "image":"assets/team/qi_chen_1.jpg",
      "description":"statistical learning theory, transfer learning, meta-learning",
      "website":"https://livreq.github.io/",
      // "email":"",
      // "linkedIn":"",
      "googleScholar":"https://scholar.google.com/citations?view_op=list_works&hl=en&hl=en&user=MqLoSeoAAAAJ",
      "github": "https://github.com/livreQ"
    },
    {
      "name":"Miroslav Bogdanovic",
      "image":"assets/team/miroslav_bogdanovic_1.jpeg",
      "description":"robotics, control, imitation and reinforcement learning. co-supervised by <a href='https://animesh.garg.tech/'>Animesh Garg</a>",
      "website":"https://miroslavbogdanovic.super.site/",
      "email":"miroslav.bogdanovic@utoronto.ca",
      "linkedIn":"https://de.linkedin.com/in/miroslavbogdanovic",
      "googleScholar":"https://scholar.google.com/citations?user=SAdQhg4AAAAJ&hl=fr",
    },
  ],
  "Research Associates":[
    {
      "name":"Sebastian Aegidius",
      "image":"assets/team/seb_rvl.jpg",
      "description":"robot perception, motion and path planning, RL",
      "email":"s.aegidius@utoronto.ca",
      "linkedIn":"https://www.linkedin.com/in/sebastian-aegidius-a7584521a/",
    },
  ],
  "PhD students":[
    {
      "name":"Kevin Xie",
      "image":"assets/team/kevin.jpg",
      "description":"reinforcement learning, control, 3D vision. co-supervised by <a href='https://www.cs.utoronto.ca/~fidler/'>Sanja Fidler</a>",
      "website":"https://kevincxie.github.io/",
      "email":"kevincxie@cs.toronto.edu",
    },
    {
      "name":"Qiao Gu",
      "image":"assets/team/qiaogu.jpg",
      "website":"https://georgegu1997.github.io/",
      "email":"q.gu@mail.utoronto.ca",
      "googleScholar":"https://scholar.google.com/citations?user=MF7ISVAAAAAJ&hl=en",
      "linkedIn":"https://www.linkedin.com/in/qiao-gu/",
      "twitter":"https://x.com/qiaogu1997",
      "description": "3D computer vision, robot learning"
    },
    {
      "name":"Skylar Hao",
      "image":"assets/team/skylar.jpg",
      "description":"sim-to-real transfer, safe learning, statistics, machine learning",
    },
    {
      "name":"Wei-Cheng Tseng",
      "image":"assets/team/weicheng_tseng_1.jpg",
      "description":"multi-agent reinforcement learning, computer vision",
      "website":"https://weichengtseng.github.io/",
    },
    {
      "name":"Sepehr Samavi",
      "image":"assets/team/sepehr_samavi_1.jpg",
      "description":"safe control, crowd navigation. co-supervised by <a href='https://www.dynsyslab.org/prof-angela-schoellig/'>Angela Schoellig</a>",
      "website":"http://dsl.utias.utoronto.ca/~sep/",
      "email":"sepehr@robotics.utias.utoronto.ca",
      "googleScholar":"https://scholar.google.ca/citations?user=_j5XWygAAAAJ&hl=en",
    },
    {
      "name":"Quentin Clark",
      "image":"assets/team/quentin.jpg",
      "description":"",
      "website":"https://chronorium.wordpress.com/about/",
      "email":"qtcc@cs.toronto.edu",
    },
  ],

  "MSc students":[
    {
      "name":"Anthony Lem",
      "image":"assets/team/anthony_lem_1.jpg",
      "description":"human pose detection and prediction",
      "email":"anthony.lem@mail.utoronto.ca",
    },
    {
      "name":"Jinbang Huang",
      "image":"assets/team/jinbang_huang_1.jpg",
      "description":"task and motion planning, control theory, optimization. co-supervised by <a href='http://stars.utias.utoronto.ca/~jkelly/'>Jonathan Kelly</a>",
      "linkedIn":"https://ca.linkedin.com/in/jinbang-huang-989526170",
    },
    {
      "name":"Blerim Abdullai",
      "image":"assets/team/blerim_abdullai_1.jpg",
      "description":"computer vision, field robotics, stochastic planning",
      "linkedIn":"https://www.linkedin.com/in/blerimabdullai",
    },

    {
      "name":"Fabian Damken",
      "image":"assets/team/fabian_damken_1.jpg",
      "description":"learning planning heuristics",
      "linkedIn":"https://linkedin.com/in/fdamken",
      "email":"fabian@damken.net",
      "website": "https://fabian.damken.net/",
      "twitter": "https://twitter.com/fabian_damken",
      "googleScholar": "https://scholar.google.com/citations?user=EM-y6hYAAAAJ&hl=en",
    },
    {
      "name":"Daniel Hocevar",
      "image":"assets/team/headshot-danielhocevar.jpg",
      "description":"computer vision, chemistry lab automation. co-supervised by <a href='https://radisiclab.com/people/'>Milica Radisic</a>",
      "linkedIn":"https://www.linkedin.com/in/danielhocevar/",
      "email":"danielhocevar1@gmail.com",
      "twitter":"https://x.com/daniel_hocevar",
    },
    {
      "name":"James Ross",
      "image":"assets/team/james_ross.jpg",
      "description":"computer vision, adversarial simulation generation",
      "googleScholar":"https://scholar.google.com/citations?user=BYyE10gAAAAJ&hl=en",
      "linkedIn":"https://www.linkedin.com/in/jamesrosstwo/",
    },
    {
      "name":"Nathan de Lara",
      "image":"assets/team/nathan.jpg",
      "description":"reinforcement learning, optimal control, optimization",
      "googleScholar":"https://scholar.google.ca/citations?user=-OvkuacAAAAJ&hl=en",
      "linkedIn":"https://www.linkedin.com/in/nathan-d-a00821126/",
      "email":"nathan.delara@mail.utoronto.ca",
      "website":"http://www.delaraanalytics.com",
    },
  ],

  "undergraduate students":[
    {
      "name":"Ruiting Chen",
      "image":"assets/team/ruiting_chen_1.jpg",
	    "description":"physics simulation for robotic tasks, adversarial scenarios generation, mesh optimization",
	    "linkedIn": "https://ca.linkedin.com/in/ruiting-chen-a24183216",
      "email":"ruiting.chen@mail.utoronto.ca",
      "website":"https://ruiting-chen.github.io",
    },
    {
      "name":"Andrew Zou Li",
      "image":"assets/team/andrew_z_li.jpg",
      "description":"task and motion planning, chemistry lab automation",
      "website": "https://andrewzl.github.io/",
      "linkedIn": "https://www.linkedin.com/in/andrewzouli/"
    },
    {
      "name":"Yuchi(Allan) Zhao",
      "image":"assets/team/yuchi_allan_zhao_1.jpg",
      "description":"robot manipulation, transparent object pose estimation, task and motion planning, chemistry lab automation",
      "website": "https://y556zhao.github.io/",
      "linkedIn": "https://www.linkedin.com/in/yuchi-allan-zhao/"
    },
    {
      "name":"Andrew Magnuson",
      "image":"assets/team/andrew_magnuson.png",
      "description":"learning from human video, latent spaces for robot manipulation",
      "email":"andrew.magnuson@mail.utoronto.ca",
      "website": "https://www.andrewmagnuson.ca/",
      "linkedIn": "https://www.linkedin.com/in/andrew-magnuson-03/",
      "twitter":"https://x.com/ajwmagnuson",
    },
    {
      "name":"Jerry Zhu",
      "image":"assets/team/jzhu2.jpeg",
      "description":"bayesian optimisation, deep learning",
      "email":"jerryzhu@uchicago.edu",
      "linkedIn": "www.linkedin.com/in/jerryzhu509",
    },
    {
      "name":"Maria Chzhen",
      "image":"assets/team/maria_c.jpeg",
      "description":"perception, implicit occupancy and flow representation.",
      "email":"maria.chzhen@mail.utoronto.ca",
      "linkedIn": "https://ca.linkedin.com/in/mariachzhen",
    },
    {
      "name":"Hossein Goli",
      "image":"assets/team/goli.jpg",
      "description":"reinforcement learning, planning, learning to search",
      "email":"hosseingoli02@gmail.com",
      "website": "https://hgoli02.github.io/",
      "linkedIn": "https://www.linkedin.com/in/hosseingoli/",
    },
    {
      "name":"Yije Wang",
      "image":"assets/team/yije.jpg",
      "description":"constrained motion planning, chemistry lab automation",
      "linkedIn":"https://www.linkedin.com/in/yijie-wang0806/",
      "email":"yijies.wang@mail.utoronto.ca",
    },
    {
      "name":"Garvish Bhutani",
      "image":"assets/team/garvish.jpg",
      "description":"robotic crowd navigation",
      "linkedIn":"https://www.linkedin.com/in/garvish-bhutani/",
      "email":"garvish.bhutani@mail.utoronto.ca",
    },
    {
      "name":"Alex Alexiev",
      "image":"assets/team/alexiev.jpg",
	    "description":"non-rigid body manipulation",
      "linkedIn":"https://www.linkedin.com/in/alexander-alexiev-125a78201/",
      "email":"alex.alexiev@mail.utoronto.ca",
	    "website": "https://alex-alexiev.github.io/",
    },


  ],


  "alumni":[
        {
      "name":"Kourosh Darvish",
      "image":"assets/team/kourosh_darvish_2.jpg",
      "description":"robotics, control, task and motion planning, machine learning, chemistry lab automation. co-supervised by <a href='https://animesh.garg.tech/'>Animesh Garg</a>. <b>next: Research Scientist, Acceleraction Consortium</b>",
      "website":"https://kouroshd.github.io/",
      "email":"kdarvish@cs.toronto.edu",
      "linkedIn":"https://it.linkedin.com/in/kouroshdarvish",
      "googleScholar":"https://scholar.google.com/citations?user=FwFFVdIAAAAJ",
    },
    {
       "name":"Andrei Ivanovic",
       "image":"assets/team/andrei_ivanovic_2.jpeg",
       "description":"trajectory prediction and planning. co-supervised by <a href='https://www.gilitschenski.org/igor/'>Igor Gilitschenski</a>. <b>next: Amazon Robotics</b>",
	"linkedIn":"https://ca.linkedin.com/in/andrei-ivanovic-438313178",
	"website": "https://aivanovic1.github.io/"
    },
    {
      "name":"Haozhe Sheng",
      "image":"assets/team/profile.jpg",
      "description":"<b>next: Google</b>",
    },
    {
      "name":"Julia Chae",
      "image":"assets/team/julia_chae_1.jpg",
      "description":"LiDAR and RGB representation learning <b>next: MIT EECS PhD</b>",
      "linkedIn":"https://ca.linkedin.com/in/julia-chae",
	"website": "https://juliachae.github.io/",
    },
    {
      "name":"Philip Huang",
      "image":"assets/team/philip.jpg",
      "description":"continual learning, field robotics, task and motion planning. co-supervised by <a href='http://asrl.utias.utoronto.ca/~tdb/'>Tim Barfoot</a>. <b>next: CMU CS PhD</b>",
      "linkedIn":"https://ca.linkedin.com/in/philip-yizhou-huang",
      "website":"https://philip-huang.github.io/",
      "googleScholar":"https://scholar.google.com/citations?hl=en&user=YDCsS5EAAAAJ"
    },
    {
      "name":"Ben Agro",
      "image":"assets/team/ben_agro.jpg",
      "description":"learning to plan, task and motion planning, manipulation. <b>next: UofT CS PhD / Waabi</b>",
      "website":"https://benagro314.github.io/",
      "twitter":"https://twitter.com/BenAgro4",
      "email":"ben.agro@mail.utoronto.ca",
    },
          {
      "name":"Mohamed Khodeir",
      "image":"assets/team/mk.jpg",
      "linkedIn":"https://ca.linkedin.com/in/khodeir",
      "description": "learning to plan, task and motion planning. <b>next: Waabi</b>",
    },

    {
      "name":"Aditya Saigal",
      "image":"assets/team/profile.jpg",
      "linkedIn":"https://ca.linkedin.com/in/aditya-saigal-221207143?trk=pub-pbmap",
      "description":"continual reinforcement learning",
    },
    {
      "name":"Hamza Dugmag",
      "image":"assets/team/hamza_dugmag_1.jpg",
	"description":"field robotics, autonomous boat",
	"website": "https://hamzadugmag.com/"
    },
    {
      "name":"Jisu Qian",
      "image":"assets/team/jisu_qian_1.jpeg",
	"description":"system identification",
	"linkedIn": "https://ca.linkedin.com/in/jisu-qian-85b18921b"
    },
    {
      "name":"Salar Hosseini",
      "image":"assets/team/salar.jpg",
      "description":"computer vision, adversarial scenario generation. <b>next: Samsung AI</b>",
      "linkedIn":"https://ca.linkedin.com/in/salar-hosseini",
      "website":"https://salarios77.github.io/",
      "googleScholar":"https://scholar.google.ca/citations?user=8OT5mY0AAAAJ&hl=en"
    },
    {
       "name":"Cathlyn Chen",
       "image":"assets/team/cathlyn_chen_1.jpg",
       "description":"backwards reachability for nonlinear systems",
       "link":"",
    },
    {
       "name":"Kathy Zhuang",
       "image":"assets/team/kathy_zhuang_1.jpeg",
	"description":"computer vision for transparent objects. <b>next: Berkeley CS MSc</b>",
	"linkedIn": "https://ca.linkedin.com/in/yue-kathy-zhuang",
	"website": "https://kathyzhuang.github.io/"
    },
    {
      "name":"Artur Kuramshin",
      "image":"assets/team/artur_kuramshin_1.jpeg",
      "description":"field robotics, autonomous boat",
      "website":"http://akuramshin.ca",
      "email":"artur.kuramshin@mail.utoronto.ca",
      "linkedIn":"https://www.linkedin.com/in/artur-kuramshin-4b926616a/",
    },
    {
      "name":"Ruiqi Wang",
      "image":"assets/team/ruiqi_wang_1.jpeg",
      "description":"adversarial scenario generation. <b>next: Stanford CS MSc</b>",
      "linkedIn":"https://www.linkedin.com/in/ruiqi-wang-3b970b150/",
    },
    {
      "name":"Charlotte Zhang",
	"image":"assets/team/profile.jpg",
	"description":"field robotics, autonomous boat",
    },
    {
      "name":"Jason Tang",
      "image":"assets/team/profile.jpg",
      "description":"continual image classification. <b>next: UofT CS MSc</b>",
    },
    {
      "name":"Pranit Chawla",
      "image":"assets/team/pranit_chawla_1.jpg",
	"description":"LiDAR and RGB representation for imitation learning. <b>next: CMU CS MSc</b>",
	"website": "https://www.pranitchawla.com/",
	"linkedIn": "https://www.linkedin.com/in/pranit-chawla-503237145/"
    },
    {
      "name":"Cong Wei",
      "image":"assets/team/cong_wei_1.jpeg",
      "linkedIn":"https://ca.linkedin.com/in/cong-wei-30",
      "description": "video summarization, generative models <b>next: Waterloo CS PhD</b>",
    },
    {
      "name":"Kamran Ramji",
      "image":"assets/team/kamran_ramji_1.jpg",
      "description":"combining imitation and reinforcement learning. <b>next: Apple</b>",
      "linkedIn":"https://ca.linkedin.com/in/kamran-ramji",
    },
    {
      "name":"Stephen Zhao",
      "image":"assets/team/stephen_zhao_1.jpeg",
	"description":"multi-agent reinforcement learning. co-supervised by <a href='http://www.cs.toronto.edu/~yangxu/'>Yang Xu</a>. <b>next: UofT CS MSc</b>",
	"linkedIn": "https://ca.linkedin.com/in/zhaostephen"
    },
    {
      "name":"Ke Dong",
      "image":"assets/team/ke_dong_1.jpg",
      "description":"control theory, learning for control, robotics, sim-to-real transfer. co-supervised by <a href='https://www.dynsyslab.org/prof-angela-schoellig/'>Angela Schoellig</a>. <b>next: Tencent AI</b>",
      "linkedIn":"https://ca.linkedin.com/in/ke-dong-7a33a9171",
    },
    {
      "name":"Dhruv Sharma",
      "image":"assets/team/dhruv_sharma_1.jpg",
      "description":"autonomous driving, robotics, computer vision. <b>next: Huawei</b>",
      "linkedIn":"https://ca.linkedin.com/in/dhruvsharmauw",
      "website":"https://sharmadhruv.weebly.com/",
      "email":"dhruv.sharma@uwaterloo.ca",
    },
    {
      "name":"Sherry Chen",
      "image":"assets/team/sherry_chen_1.jpg",
      "description":"video representation learning. <b>next: UTIAS MSc</b>",
      "linkedIn":"https://ca.linkedin.com/in/sherry-chen-engsci127",
    },
    {
      "name":"Sally Chen",
      "image":"assets/team/sally_chen_1.jpg",
      "description":"differentiable rendering for self-driving simulators. <b>next: CMU CS MSc</b>",
      "linkedIn":"https://ca.linkedin.com/in/chuhan-chen",
    },
    {
      "name":"Homanga Bharadhwaj",
      "image":"assets/team/homanga_bharadhwaj_1.jpg",
      "description":"reinforcement learning, safe exploration, robotics, recommender systems. co-supervised by <a href='https://animesh.garg.tech/'>Animesh Garg</a>. <b>next: CMU CS PhD</b>",
      "website":"https://homangab.github.io/",
      "email":"homangablackhole36@gmail.com",
      "googleScholar":"https://scholar.google.ca/citations?user=wwW4HRQAAAAJ&hl=en",
      "twitter":"https://twitter.com/mangahomanga"
    },
    {
      "name":"Chris Agia",
      "image":"assets/team/chris_agia_1.jpg",
      "description":"3d vision for autonomous driving. <b>next: Stanford CS PhD</b>",
      "website":"https://agiachris.github.io/",
      "email":"cagia@stanford.edu",
      "linkedIn":"https://www.linkedin.com/in/agiachris/",
    },
    {
      "name":"Ali Kuwajerwala",
      "image":"assets/team/ali_kuwajerwala_1.jpg",
      "description":"backwards reachability for nonlinear systems. <b>next: MILA MSc</b>",
      "linkedIn":"https://ca.linkedin.com/in/alikuwajerwala",
    },
    {
      "name":"Melissa Mozifian",
      "image":"assets/team/melissa_mozifian_1.jpg",
      "description":"visiting phd student, McGill/MILA. combining imitation and reinforcement learning. <b>visitor: MILA, McGill</b>",
      "website":"https://melfm.github.io/about.html",
      "googleScholar":"https://scholar.google.com/citations?user=sygJEU0AAAAJ&hl=en",
    },
    {
      "name":"Shichen Lu",
      "image":"assets/team/shichen_lu_1.jpeg",
      "description":"control and reinforcement learning for mobile manipulation. <b>next: UTIAS MSc</b",
      "linkedIn":"https://ca.linkedin.com/in/shichen-lu",
    },
    {
      "name":"Siyun Li",
      "image":"assets/team/profile.jpg",
      "description":"adversarial examples for self-driving simulators. <b>next: Stanford CS MSc</b>",
      "linkedIn":"https://ca.linkedin.com/in/siyun-li",
    },
    {
      "name":"Yuchen Wu",
      "image":"assets/team/yuchen_wu_1.jpg",
      "description":"combining imitation and reinforcement learning. <b>next: UTIAS MSc</b>",
      "linkedIn":"https://ca.linkedin.com/in/yuchen-wu-a9838a14a",
    },
    {
      "name":"Zidong Weng",
      "image":"assets/team/profile.jpg",
      "description":"out-of-distribution detection for image and lidar data. <b>next: Intel</b>",
      "linkedIn":"https://ca.linkedin.com/in/zidong-weng-232035134",
    },
    {
      "name":"Zihan Wang",
      "image":"assets/team/zihan_wang_1.jpg",
      "description":"imitation learning for robotics. <b>next: Stanford CS MSc</b>",
      "website":"https://avinwangzh.github.io/Personal-Website/",
      "email":"avin.wangzihan@gmail.com",
      "linkedIn":"https://ca.linkedin.com/in/zihan-wang-70aa47ab"
    },
    {
      "name":"Sirui (Ariel) Chen",
      "image":"assets/team/ariel_chen_1.jpg",
	    "description":"pedestrian trajectory prediction. <b>next: Stanford MSc</b>",
	    "website": "https://github.com/arielchen07",
        "linkedIn": "https://ca.linkedin.com/in/sirui-chen-6492a0232"
    },
    {
      "name":"Kelly Zhu",
      "image":"assets/team/kellyzhu.jpg",
      "description":"trajectory prediction, uncertainty-aware planning <b>next: UofT CS MSc</b>",
      "googleScholar":"https://scholar.google.com/citations?user=4k-1QxAAAAAJ&hl=en&oi=sra",
      "linkedin":"https://www.linkedin.com/in/kellyzhu-ca",
      "email":"zhu@cs.toronto.edu",
      "website":"https://kellyzoo.github.io",
    },
    {
      "name":"Yewon Lee",
      "image":"assets/team/yewon_lee_1.jpeg",
      "description":"task and motion planning <b>next: University of Washington CS PhD</b>",
      "website":"https://yewon-lee.github.io/",
      "email":"yewonlee@cs.toronto.edu",
    },
    {
      "name":"Yasasa Abeysirigoonawardena",
      "image":"assets/team/yasasa_abeysirigoonawardena_1.jpeg",
      "description":"adversarial scenario generation, neural rendering. <b>next: Waabi</b>",
	"linkedIn":"https://ca.linkedin.com/in/yasasa-abeysirigoonawardena-819229198",
	"website": "https://www.yasasa.me/"
    },
  ],
}
